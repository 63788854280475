<template>
    <div style="display: flex;justify-content: center;">
        <div class="page">
            <h3>个人健康信息调查量表</h3>
            <div>
                <el-row class="heard">
                    <el-col :span="6">
                        <span>编号：{{form.num}}</span>
                    </el-col>
                    <el-col :span="11">
                        请根据<span style="font-size: 16px;font-weight: 700">近两月</span>真实感受选择答案，○单选，□多选。
                    </el-col>
                    <el-col :span="7">
                        填表日期： {{form.year}} 年 {{form.month}} 月 {{form.day}} 日
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px;height:calc(100vh - 200px);overflow-y:auto;">
                    <table border="0" width="100%" style="margin: auto!important;">
                        <tbody>
                        <tr>
                            <td rowspan="5" width="40px">基本信息</td>
                            <td width="130px">姓名</td>
                            <td width="500px">{{form.name||''}}</td>
                            <td width="100px">性别</td>
                            <td width="280px">
                                <el-radio-group v-model="form.gender">
                                    <el-radio label="男">男</el-radio>
                                    <el-radio label="女">女</el-radio>
                                    <el-radio label="其他">其他</el-radio>
                                </el-radio-group>
                            </td>
                            <td width="300px">生日(必填)</td>
                            <td width="300px"></td>
                        </tr>
                        <tr>
                            <td>现住地址</td>
                            <td colspan="2">{{form.addres}}</td>
                            <td colspan="3">
                                <el-checkbox-group v-model="form.ty">
                                    <el-checkbox label="1">同意使用个人网络空间</el-checkbox>
                                    <el-checkbox label="2">同意接受健康干预服务</el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr>
                            <td>手机号码</td>
                            <td colspan="2">{{form.phone}}</td>
                            <td colspan="1">E-mail</td>
                            <td colspan="3">{{form.email}}</td>
                        </tr>
                        <tr>
                            <td>ABO 血型</td>
                            <td colspan="2">
                                <el-radio-group v-model="form.xx">
                                    <el-radio :label="1">A型</el-radio>
                                    <el-radio :label="2">B型</el-radio>
                                    <el-radio :label="3">O型</el-radio>
                                    <el-radio :label="4">AB型</el-radio>
                                    <el-radio :label="5">不明</el-radio>
                                </el-radio-group>
                            </td>
                            <td>婚姻</td>
                            <td colspan="4">
                                <el-radio-group v-model="form.hy">
                                    <el-radio :label="1">未婚</el-radio>
                                    <el-radio :label="2">已婚</el-radio>
                                    <el-radio :label="3">丧偶</el-radio>
                                    <el-radio :label="4">离婚</el-radio>
                                    <el-radio :label="5">其他</el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td>职业类型</td>
                            <td colspan="5">
                                <el-radio-group v-model="form.zy">
                                    <el-radio :label="1">行政</el-radio>
                                    <el-radio :label="2">技术</el-radio>
                                    <el-radio :label="3">办事</el-radio>
                                    <el-radio :label="4">服务</el-radio>
                                    <el-radio :label="5">农林牧渔</el-radio>
                                    <el-radio :label="6">工人</el-radio>
                                    <el-radio :label="7">军人</el-radio>
                                    <el-radio :label="8">离退休人员</el-radio>
                                    <el-radio :label="9">其他</el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="6">患病情况</td>
                            <td>疾病</td>
                            <td colspan="5">
                                <div style="display: flex">
                                    <div v-for='(item,index) in jbList'
                                         :key="index" :style="'width:'+ item.length*17 +'px'"> {{item}}|</div>
                                </div>
                            </td>
                        </tr>
                        <tr class="hbinfo">
                            <td>父亲</td>
                            <td colspan="5">
                                <el-checkbox-group v-model="form.fq">
                                    <el-checkbox :label="item" v-for='(item,index) in jbList' :key="index" :style="'width:'+ item.length*17 +'px'"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr class="hbinfo">
                            <td>母亲</td>
                            <td colspan="5">
                                <el-checkbox-group v-model="form.mq">
                                    <el-checkbox :label="item" v-for='(item,index) in jbList' :key="index" :style="'width:'+ item.length*17 +'px'"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr class="hbinfo">
                            <td>祖父母</td>
                            <td colspan="5">
                                <el-checkbox-group v-model="form.zfm">
                                    <el-checkbox :label="item" v-for='(item,index) in jbList' :key="index" :style="'width:'+ item.length*17 +'px'"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr class="hbinfo">
                            <td>外祖父母</td>
                            <td colspan="5">
                                <el-checkbox-group v-model="form.wzfm">
                                    <el-checkbox :label="item" v-for='(item,index) in jbList' :key="index" :style="'width:'+ item.length*17 +'px'"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr class="hbinfo">
                            <td>本人</td>
                            <td colspan="5">
                                <el-checkbox-group v-model="form.br">
                                    <el-checkbox :label="item" v-for='(item,index) in jbList' :key="index" :style="'width:'+ item.length*17 +'px'"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table border="0" width="100%" style="margin: auto!important;" class="jwinfo">
                        <tbody>
                        <tr>
                            <td width="106px">既往情况</td>
                            <td width="131px">接触史：</td>
                            <td>
                                <el-checkbox-group v-model="form.br">
                                    <el-checkbox :label="1">油烟</el-checkbox>
                                    <el-checkbox :label="2">粉烟尘</el-checkbox>
                                    <el-checkbox :label="3">毒物致癌物</el-checkbox>
                                    <el-checkbox :label="4">高温</el-checkbox>
                                    <el-checkbox :label="5">低温</el-checkbox>
                                    <el-checkbox :label="6">噪音、震动</el-checkbox>
                                    <el-checkbox :label="7">辐射</el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table border="0" width="100%" style="margin: auto!important;">
                        <tbody>
                        <tr v-for="(items,index) in ysList" :key="index">
                            <td rowspan="5" width="25px" v-if="index==0">饮食习惯</td>
                            <td width="212px">{{items.title}}</td>
                            <td v-if="items.type=='checkbox'">
                                <el-checkbox-group v-model="form.ys">
                                    <el-checkbox :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-checkbox>
                                </el-checkbox-group>
                                <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                            </td>
                            <td v-else>
                                <el-radio-group v-model="form.zy">
                                    <el-radio :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-radio>
                                </el-radio-group>
                                <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr v-for="(items,index) in srList" :key="index">
                            <td rowspan="9" width="25px" v-if="index==0">个人膳食摄入</td>
                            <td>{{items.title}}</td>
                            <td>
                                <el-radio-group v-model="form.zy">
                                    <el-radio :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-radio>
                                </el-radio-group>
                                <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr v-for="(items,index) in ydList" :key="index">
                            <td rowspan="5" width="25px" v-if="index==0">运动锻炼</td>
                            <td>{{items.title}}</td>
                            <td>
                                <el-radio-group v-model="form.zy">
                                    <el-radio :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-radio>
                                </el-radio-group>
                                <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr v-for="(items,index) in xyList" :key="index">
                            <td rowspan="4" width="25px" v-if="index==0">吸烟情况</td>
                            <td>{{items.title}}</td>
                            <td>
                                <el-radio-group v-model="form.zy">
                                    <el-radio :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-radio>
                                </el-radio-group>
                                <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr v-for="(items,index) in yjList" :key="index">
                            <td rowspan="4" width="25px" v-if="index==0">饮酒情况</td>
                            <td>{{items.title}}</td>
                            <td>
                                <el-radio-group v-model="form.zy">
                                    <el-radio :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-radio>
                                </el-radio-group>
                                <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                            <tr v-for="(items,index) in qiList" :key="index">
                                <td rowspan="5" width="25px" v-if="index==0">其他</td>
                                <td>{{items.title}}</td>
                                <td>
                                    <el-radio-group v-model="form.zy">
                                        <el-radio :label="ids.value" v-for="(ids,idx) in items.option" :key="idx"></el-radio>
                                    </el-radio-group>
                                    <span v-if="items.remark" style="font-weight: 700;float: right"> {{items.remark}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-row>
            </div>
        </div>
    </div>

</template>

<script>
    import data from './data'
    export default {
        name: 'questionnaire',
        data() {
            return {
                form: {
                    num: "202312100001",
                    year: "2023",
                    month: "12",
                    day: "10",
                    name:"",
                    ty: [],
                    fq: [],
                    mq: [],
                    zfm: [],
                    wzfm: [],
                    br: [],
                    ys:[]
                },
                item: {},
                name: "",
                jbList:["高血压病","糖尿病","冠心病","血脂异常","肥胖症","脑卒中","骨质疏松","阿尔茨海默病","肺癌 ","肝癌","胃癌","前列腺癌","乳腺癌","宫颈癌"],
                qiList:data.form.qita,
                yjList:data.form.yj,
                xyList:data.form.xy,
                ydList:data.form.yd,
                srList:data.form.sr,
                ysList:data.form.ys

            }
        },
        methods: {
            async getData() {
                try {
                    let pk = this.$route.params.id
                    this.form.name = this.$route.params.name
                    if (pk) {
                        // const resp = await this.$http.get(`/register/${pk}`)
                        // if (!resp.data.data) {
                        //     this.$message.error(resp.data.message)
                        // } else {
                        //     this.form = resp.data.data
                        // }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.form.name = this.$route.query.name
            this.form.gender = this.$route.query.gender
            this.form.phone = this.$route.query.phone

            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        font-size: 14px;
        width: 900px;

        h3 {
            text-align: center;
        }

        .heard {
            border-bottom: 1px solid #999999;
        }

        table {
            border-collapse: collapse;
        }

        table tbody tr {
            font-size: 14px;
        }

        table tbody td {
            padding: 8px 5px;
            border: 1px solid black;
            /*text-align: center !important;*/
        }

        .jwinfo tbody td {
            border-top: inherit !important;
            border-bottom: inherit !important;
        }

        ::v-deep .el-radio {
            margin-right: 5px;
        }

        ::v-deep .el-checkbox {
            margin-left: 1px;
            margin-right: 0px;
        }

        .hbinfo ::v-deep .el-checkbox__label {
            display: none;
        }
    }
</style>
